<template>
  <div id="app">
    <el-image ref="imageLoad" @click="" style="width: 0;height: 0;display: flex;" :src="imageUrl"
      :preview-src-list="srcList">
    </el-image>
    <vue-drag-resize @resizing="changeIframeDivStyle(true)" @resizestop="changeIframeDivStyle(false)"
      @dragging="changeIframeDivStyle(true)" @dragstop="changeIframeDivStyle(false)" v-show="showlive" :minw="320"
      :minh="200" :w="320" :h="200" :x="600" :y="10" :z="3000" :isActive="true" :isResizable="true" :aspectRatio="true">
      <div id="myvideo" ref="videobox" style="width: 100%;height: 100%" @mouseover="showContent = true"
        @mouseout="showContent = false" @dblclick="getMousePosition('dock')">
        <div class="showbottom" v-show="showContent">
          <i class="el-icon-refresh" @click="changeinout()"></i>
          <i class="el-icon-s-promotion" @click="share('dock')"></i>
          <i v-if="!isFullscreen" class="el-icon-full-screen"
            style="position: absolute;right: 30px;top:5px;z-index:3001;cursor: pointer;font-size: 16px"
            @click="clickFullScreen('dock')"></i>
          <i v-else class="custom-icon" @click="clickFullScreen('dock')"></i>
          <i class="el-icon-close"
            style=" position: absolute;right: 10px;top: 5px;z-index: 3001;cursor: pointer;font-size: 16px"
            @click="closelive('dock')"></i>
        </div>
      </div>
      <div class="dockshareshow" v-show="dockshareshow">
        <header class="dock-header">
          <span>机场直播分享</span>
          <i class="el-icon-close"
            style=" position: absolute;right: 10px;top: 5px;z-index: 3001;cursor: pointer;font-size: 16px"
            @click="closeshare('dock')"></i>
        </header>
        <main class="dock-main">
          <div class="dock-content">直播分享会消耗当前组织的直播用量,消耗的直播用量为所有访问分享直播页面的用户观看直播的总时长</div>
          <div class="showshare">
            <div class="showsharebox">
              <div style="height: 50px"><span style="margin-right: 20px">有效期</span><span
                  style="margin-left: 20px;color: rgb(134, 134, 134);">1小时</span></div>
              <div class="shareurl">
                <div class="shareurlleft" style="margin-right: 20px">分享链接</div>
                <el-tooltip class="item" effect="dark" :content="dockshareurl" placement="bottom"
                  popper-class="tooltip-width">
                  <div class="shareurlright">{{ dockshareurl }}</div>
                </el-tooltip>
              </div>
              <div class="qrcodebox">
                <canvas id="qrcode"></canvas>
                <div style="height: 20px;font-size: 12px;color: rgb(45,140,240)">
                  {{ iscopy ? '复制成功' : '' }}</div>
                <el-button type="primary" @click="copyurl('dock')">复制分享链接</el-button>
              </div>
            </div>
          </div>
        </main>
      </div>
    </vue-drag-resize>
    <vue-drag-resize @resizing="changeIframeDivStyle(true)" @resizestop="changeIframeDivStyle(false)"
      @dragging="changeIframeDivStyle(true)" @dragstop="changeIframeDivStyle(false)" v-show="droneshowlive" :minw="320"
      :minh="200" :w="320" :h="200" :x="250" :y="10" :z="3000" :isActive="true" :isResizable="true" :aspectRatio="true">
      <div id="dronevideo" ref="dronevideobox" @mouseover="droneshowContent = true" @mouseout="droneshowContent = false"
        @dblclick="getMousePosition('drone')">
        <div class="droneshowbottom" v-show="droneshowContent">
          <i class="el-icon-s-promotion" @click="share('drone')"></i>
          <i v-if="!isFullscreen" id="dronescreen" class="el-icon-full-screen"
            style="position: absolute;right: 30px;top:5px;z-index:3001;cursor: pointer;font-size: 16px"
            @click="clickFullScreen('drone')"></i>
          <i v-else class="dronecustomicon" @click="clickFullScreen('drone')"></i>
          <i id="droneclose" class="el-icon-close"
            style=" position: absolute;right: 10px;top: 5px;z-index: 3001;cursor: pointer;font-size: 16px"
            @click="closelive('drone')"></i>
        </div>
      </div>
      <div class="droneshareshow" v-show="droneshareshow">
        <header class="drone-header">
          <span>无人机直播分享</span>
          <i class="el-icon-close"
            style=" position: absolute;right: 10px;top: 5px;z-index: 3001;cursor: pointer;font-size: 16px"
            @click="closeshare('drone')"></i>
        </header>
        <main class="drone-main">
          <div class="drone-content">直播分享会消耗当前组织的直播用量,消耗的直播用量为所有访问分享直播页面的用户观看直播的总时长</div>
          <div class="showshare">
            <div class="showsharebox">
              <div style="height: 50px"><span style="margin-right: 20px">有效期</span><span
                  style="margin-left: 20px;color: rgb(134, 134, 134);">1小时</span></div>
              <div class="shareurl">
                <div class="shareurlleft" style="margin-right: 20px">分享链接</div>
                <el-tooltip class="item" effect="dark" :content="droneshareurl" placement="bottom"
                  popper-class="tooltip-width">
                  <div class="shareurlright">{{ droneshareurl }}</div>
                </el-tooltip>
              </div>
              <div class="qrcodebox">
                <canvas id="droneqrcode"></canvas>
                <div style="height: 20px;font-size: 12px;color: rgb(45,140,240)">
                  {{ isdronecopy ? '复制成功' : '' }}</div>
                <el-button type="primary" @click="copyurl('drone')">复制分享链接</el-button>
              </div>
            </div>
          </div>
        </main>
      </div>
    </vue-drag-resize>
    <transition name="fade" mode="out-in">
      <router-view ref="childmethod" :key="$route.path"></router-view>
    </transition>
  </div>
</template>
<script>
import VueDragResize from 'vue-drag-resize';
import QRCode from 'qrcode';
import { request, requestwrj, requestfly } from '@/utils/request';
import { GET_HHTOKEN, GET_MENU, GET_PLAYLOAD, GET_PROJECTID, GET_USERID } from './utils/token';
export default {
  provide() {
    return {
      newTab: this.newTab,
      removeTabByTabName: this.removeTabByTabName,
      message: this.message,
      bodyLive: this.seeLive,
      addDroneLog: this.addDroneLog,
      isshowlive: this.isshowlive,
      closelive: this.closelive,
      imageLoading: this.imageLoading,
      clickImage: this.clickImage,

    }
  },
  data() {
    return {
      masklayer: false,
      droneshowContent: false,
      droneshowlive: false,
      isDrag: false,
      showContent: false,
      isFullscreen: false,
      device: '',
      showlive: false,
      mediaShow: false,
      dockLive: false,
      droneLive: false,
      dockshowlive: false,
      menus: '',
      activeIndex2: 0,
      editableTabs: [{
        title: '工作台',
        name: 'main',
        url: '../home/main',
        closable: false
      }],
      tabIndex: 1,
      editableTabsValue: 'main',
      roundIndex: 0,
      ids: [],
      mainDockLiveShow: false,
      mainDroneLiveShow: false,
      rtc: {},
      LiveDevice: {},
      converter: "",
      dockRtc: {},
      droneRtc: {},
      devicesn: '',
      videoid: '',
      startdock: false,
      startdrone: false,
      direction: '',
      dockSN: '',
      dockvideoid: '',
      droneSN: '',
      dronevideoid: '',
      flag: 0,
      dockshareshow: false,
      droneshareshow: false,
      isshare: false,
      dockshareurl: '',
      droneshareurl: '',
      iscopy: false,
      isdronecopy: false,
      uid: '',
      options: {
        //appid
        appId: "b1a3cab94bab4f61a2f4a2b71068a35f",
        // 频道
        channel: "",
        // 根据频道生成的临时token
        token: "",
        // 通道用户ID
        uid: Math.floor(Date.now() / 1000),
      },
      timer: null,
      allDocksEmpty: false,
      allDronesEmpty: false,
      url: "../cus/cusList",
      matchedRoutes: '',
      imageUrl: '',
      srcList: [],
      meta: {
        breadcrumb: ['工作台'],
        url: '../home/main'
      },
      cameraposition:1,
      devicetype: ''
    };
  },
  components: {
    'vue-drag-resize': VueDragResize
  },
  mounted() {
    document.addEventListener('fullscreenchange', this.handleFullscreenChange);
    this.getMenu();
  },
  beforeDestroy() {
    document.removeEventListener('fullscreenchange', this.handleFullscreenChange);
  },
  methods: {
    changeinout() {
      if (this.cameraposition == 0) {
        this.cameraposition = 1;
        requestfly.post('/Live/LiveCameraChange?devicesn=' + this.devicesn + '&cameraposition=' + this.cameraposition).then(res => {
          if (res.statusCode == 200) {

            this.$message({ message: res.message, type: 'success' })
          } else {

            this.$message({ message: res.message, type: 'error' })
          }

        })
      } else if (this.cameraposition == 1) {
        this.cameraposition = 0;
        requestfly.post('/Live/LiveCameraChange?devicesn=' + this.devicesn + '&cameraposition=' + this.cameraposition).then(res => {
          if (res.statusCode == 200) {

            this.$message({ message: res.message, type: 'success' })
          } else {
            this.$message({ message: res.message, type: 'error' })
          }
        })
      }

    },
    getMenu() {
      this.menus = JSON.parse(GET_MENU())
    },
    async copyurl(device) {
      if (device == "dock") {
        const textarea = document.createElement('textarea');
        textarea.value = this.dockshareurl;
        textarea.style.position = 'fixed';  // 防止出现滚动条
        document.body.appendChild(textarea);
        textarea.select();
        try {
          const successful = document.execCommand('copy');
          if (successful) {
            this.iscopy = true;
          } else {
            this.message('复制失败', 'error');
          }
        } catch (err) {
          this.message('复制失败:' + err, 'error');
        }
        document.body.removeChild(textarea);
      } else if (device == "drone") {
        const textarea = document.createElement('textarea');
        textarea.value = this.droneshareurl;
        textarea.style.position = 'fixed';  // 防止出现滚动条
        document.body.appendChild(textarea);
        textarea.select();
        try {
          const successful = document.execCommand('copy');
          if (successful) {
            this.isdronecopy = true;
          } else {
            this.message('复制失败', 'error');
          }
        } catch (err) {
          this.message('复制失败:' + err, 'error');
        }
        document.body.removeChild(textarea);
      }

    },
    closeshare(device) {
      if (device == "dock") {
        this.dockshareshow = false;
        this.iscopy = false;
        let qrcode = document.getElementById('qrcode');
        while (qrcode.firstChild) {
          qrcode.removeChild(qrcode.firstChild);
        }

      } else if (device == "drone") {
        this.droneshareshow = false;
        this.isdronecopy = false;
        let qrcode = document.getElementById('droneqrcode');
        while (qrcode.firstChild) {
          qrcode.removeChild(qrcode.firstChild);
        }
      }
    },
    imageLoading(imageUrl, srcList) {
      this.imageUrl = imageUrl;
      this.srcList = srcList;
    },
    clickImage() {
      this.$nextTick(() => {
        this.$refs.imageLoad.clickHandler();
      })
    },
    message(content, type) {
      this.$message({
        message: content,
        type: type,
      });
    },
    share(device) {
      if (device == "dock" && !this.dockshareshow) {
        this.generateQRCode(device);
        this.dockshareshow = true;
      } else if (device == "drone" && !this.droneshareshow) {
        this.generateQRCode(device);
        this.droneshareshow = true;
      }

    },
    generateQRCode(device) {
      if (device == "dock") {
        QRCode.toCanvas(document.getElementById('qrcode'), this.dockshareurl, {
          width: 160,
          height: 160,
          color: {
            dark: '#000000',
            light: '#ffffff',
          },
        })
      } else if (device == "drone") {
        QRCode.toCanvas(document.getElementById('droneqrcode'), this.droneshareurl, {
          width: 160,
          height: 160,
          color: {
            dark: '#000000',
            light: '#ffffff',
          },
        })
      }

    },
    getMousePosition(devicesn) {
      this.changeIframeDivStyle(false);
      if (devicesn == 'dock') {
        const container = this.$refs.videobox;
        if (!this.isFullscreen) {
          container.requestFullscreen().then(

          ).catch(function (err) {
            // 全屏失败时的错误处理
            console.error("无法进入全屏模式: ", err);
          });
        } else {
          document.exitFullscreen().then(

          ).catch(function (err) {
            console.error("无法退出全屏模式: ", err);
          });
        }
      } else {
        const container = this.$refs.dronevideobox;
        if (!this.isFullscreen) {
          container.requestFullscreen().then(

          ).catch(function (err) {
            // 全屏失败时的错误处理
            console.error("无法进入全屏模式: ", err);
          });
        } else {
          document.exitFullscreen().then(

          ).catch(function (err) {
            console.error("无法退出全屏模式: ", err);
          });
        }
      }
    },
    handleFullscreenChange() {
      this.isFullscreen = !this.isFullscreen;
    },

    changeIframeDivStyle(flag) {
      this.masklayer = flag;
    },
    clickFullScreen(devicesn) {
      if (devicesn == 'dock') {
        const container = this.$refs.videobox;
        if (!this.isFullscreen) {
          container.requestFullscreen().then(

          ).catch(function (err) {
            // 全屏失败时的错误处理
            console.error("无法进入全屏模式: ", err);
          });
        } else {
          document.exitFullscreen().then(

          ).catch(function (err) {
            console.error("无法退出全屏模式: ", err);
          });
        }
      } else {
        const container = this.$refs.dronevideobox;
        if (!this.isFullscreen) {
          container.requestFullscreen().then(

          ).catch(function (err) {
            // 全屏失败时的错误处理
            console.error("无法进入全屏模式: ", err);
          });
        } else {
          document.exitFullscreen().then(

          ).catch(function (err) {
            console.error("无法退出全屏模式: ", err);
          });
        }
      }

    },
    isshowlive() {
      if (!this.startdock && !this.startdrone) {
        this.showlive = false;;
        this.droneshowlive = false;;
        this.startdock = false;
        this.startdrone = false;
      } else if (!this.startdrone) {
        this.droneshowlive = false;
        this.startdrone = false;
      }

    },
    removeTabByTabName(name) {
      var targetName = "";
      this.editableTabs.forEach(tab => {
        if (tab.title == name)
          targetName = tab.name;
      });
      if (targetName)
        this.removeTab(targetName);
    },
    reloadlive() {
      this.showlive = false;
      this.droneshowlive = false;
      this.startdock = false;
      this.startdrone = false;
      var dock = document.getElementById('myvideo');
      while (dock.childNodes.length > 1) {
        dock.removeChild(dock.childNodes[1]);
      }
      var drone = document.getElementById('dronevideo');
      while (drone.childNodes.length > 1) {
        drone.removeChild(drone.childNodes[1]);
      }
      sessionStorage.setItem('dockshowlive', false)
      sessionStorage.setItem('droneshowlive', false)

    },
    updateshowlive(device) {
      // let iframe = document.querySelectorAll('iframe');
      // let newiframe = '';
      // for (var i = 0; i < iframe.length; i++) {
      //   if (iframe[i].src.includes("control/remote")) {
      //     newiframe = iframe[i]
      //   }
      // }
      // if (newiframe) {
      //   newiframe?.addEventListener('load', function () {
      //     newiframe?.contentWindow.postMessage(1, '*');
      //   });
      if (device == "dock") {
        this.$refs.childmethod.dockshowlive = false
        // newiframe?.contentWindow.postMessage({ dockshowlive: 1 }, '*');
      } else if (device == "drone") {
        this.$refs.childmethod.droneshowlive = false;
        // newiframe?.contentWindow.postMessage({ droneshowlive: 1 }, '*');
      } else if (device == "dockloading") {
        this.$refs.childmethod.dockloading = false;
        this.$refs.childmethod.dockshowlive = true;
        // newiframe?.contentWindow.postMessage({ dockloading: 0 }, '*');
      } else if (device == "droneloading") {
        // newiframe?.contentWindow.postMessage({ droneloading: 0 }, '*');
        this.$refs.childmethod.droneloading = false;
        this.$refs.childmethod.droneshowlive = true;
      }
      // }
    },

    closelive(device, type) {
      if (device == 'dock') {
        sessionStorage.setItem('dockshowlive', false)
        this.showlive = false;
        this.startdock = false;
        this.closeshare(device);
        this.leaveLive(this.dockRtc, device);
        if (type == 0) {
             this.stopDockLive(this.dockSN, this.dockvideoid);
        }
        // this.stopDockLive(this.dockSN, this.dockvideoid);
        this.updateshowlive(device)
        if (!this.showlive && !this.droneshowlive) {
          this.$refs.childmethod.showlive = false;
        }
        var dock = document.getElementById('myvideo');
        while (dock.childNodes.length > 1) {
          dock.removeChild(dock.childNodes[1]);
        }


      } else if (device == 'drone') {
        sessionStorage.setItem('droneshowlive', false)
        this.droneshareshow = false;
        this.droneshowlive = false;
        this.closeshare(device);
        this.startdrone = false;
        this.leaveLive(this.droneRtc, device);
        if (type == 1) {
            this.stopDroneLive(this.droneSN, this.dronevideoid);
        }
        // this.stopDroneLive(this.droneSN, this.dronevideoid);
        this.updateshowlive(device)
        if (!this.showlive && !this.droneshowlive) {
          this.$refs.childmethod.showlive = false;
        }
        var drone = document.getElementById('dronevideo');
        while (drone.childNodes.length > 1) {
          drone.removeChild(drone.childNodes[1]);
        }
      }
      if (this.isFullscreen) {
        document.exitFullscreen().then(

        ).catch(function (err) {
          console.error("无法退出全屏模式: ", err);
        });
      }


    },
    liveStop() {//关闭直播
      var devicesn = this.devicesn;
      var videoid = this.videoid;
      requestfly.post("/Live/LiveStopPush?devicesn=" + devicesn).then((res) => {
        var result = res;
        if (result.successful) {
          this.$message({ message: "关闭直播成功", type: "success" });
          const container = document.getElementById("myvideo");
          container.removeChild(remotePlayerContainer);
          this.$emit('update', 0);
        } else {
          this.$message.error(result.message);
        }
      }
      ).catch(err => {
        this.$message.error(err.message);
      })
    },
    mainStopPushToAIPlatform() {//退出AI
      requestwrj.post("/api/Live/StopPushToAIPlatform?converterID=" + this.converter).then((res) => {
        var result = res;
        if (result.successful) {

        } else {
          this.$message.error(result.message);
        };
      });
    },
    handleClose(done) {
      this.stopLive();
      this.mainleaveLive(this.rtc);
      this.mainStopPushToAIPlatform();
      done();
    },
    async mainleaveLive(rtc) {
      rtc.localAudioTrack && rtc.localAudioTrack.close();
      rtc.localVideoTrack && rtc.localVideoTrack.close();
      // Traverse all remote users.
      rtc.client.remoteUsers.forEach(user => {
        // Destroy the dynamically created DIV containers.
        const playerContainer = document.getElementById(user.uid);
        playerContainer && playerContainer.remove();
      });

      // Leave the channel.

      await rtc.client.leave();
    },
    seeLive(device, SN, videoID, loading) { //开启直播
      this.device = device;
      this.videoid = videoID;
      var rtc = {
        client: AgoraRTC.createClient({ mode: "live", codec: "vp8" }),
      };
      this.rtc = rtc;
      if (device == "dock") {//判断设备类型
        this.dockRtc = rtc;
        this.dockvideoid = videoID;
        this.devicesn = SN;
        this.devicetype = 0;
      } else {
        this.droneRtc = rtc;
        this.dronevideoid = videoID;
        this.dronedevicesn = SN;
        this.devicetype = 1;
      }
      this.liveloading = true;
      requestfly.post("/Live/LiveStartPushAgora?devicesn=" + SN + "&videoquality=0" + "&devicetype=" + this.devicetype).then((res) => {
        var result = res;
        this.liveloading = false;
        if (result.successful) {
          if (device == "drone") {
            this.$emit('update', 1);
          }
          this.options.channel = result.data.channel;
          this.options.token = result.data.token;
          var obj = { SN: SN, videoID: videoID }


          this.$nextTick(() => {
            // this.live(rtc,device);
            this.live(rtc, device, this.options, obj, loading)
          });

        } else {
          this.$message.error(result.message);
        }
      }).catch(err => {
        this.$message.error(err.message);
      })
    },
    updateloading(device) {

      if (device == "dockloading") {
        this.$refs.childmethod.dockloading = false;

      } else if (device == "droneloading") {
        this.$refs.childmethod.droneloading = false;

      } else if (device == "dockmonitor") {
        this.$refs.childmethod.dockmonitor = false;
      } else if (device == "dronemonitor") {
        this.$refs.childmethod.dronemonitor = false;
      }
    },
    async live(rtc, device, options, obj, loading) { //打开直播窗口
      if (this.startdock && device == 'dock') {
        this.$message.error("机场直播已打开");
        this.updateloading(loading)
        return
      } else if (this.startdrone && device == 'drone') {
        this.$message.error("无人机直播已打开");
        this.updateloading(loading)
        return
      }
      this.device = device;
      this.devicesn = obj.SN;
      this.videoid = obj.videoID;
      let url = new URL(window.location.href)
      this.uid = options.uid
      var elementID = ''
      if (device == "dock") {//判断设备类型
        sessionStorage.setItem('dockshowlive', true);
        this.dockRtc = rtc;
        this.dockSN = obj.SN;
        this.dockvideoid = obj.videoID;
        this.dockshareurl = url.origin + "/share/shareVideo?token=" + encodeURIComponent(options.token) + "&channel=" + options.channel;

      } else {
        sessionStorage.setItem('droneshowlive', true);
        this.droneRtc = rtc;
        this.droneSN = obj.SN;
        this.dronevideoid = obj.videoID;
        this.droneshareurl = url.origin + "/share/shareVideo?token=" + encodeURIComponent(options.token) + "&channel=" + options.channel;

      }

      rtc.client.setClientRole("audience", { level: 1 });
      options.uid = Math.floor(Math.random() * 5000);
      await rtc.client.join(this.options.appId, options.channel, options.token, options.uid);
      rtc.client.on("user-published", async (user, mediaType) => {
        // Subscribe to a remote user.
        await rtc.client.subscribe(user, mediaType);
        if (device == "dock") {
          elementID = "dockPlayer";
          this.dockLiveShow = true;
          this.showlive = true;
          this.startdock = true;
          this.updateloading(loading)
          this.updateshowlive('dockloading')
          this.$message({ message: "开始机场直播", type: "success" });
          if (mediaType == "video") {
            const remoteVideoTrack = user.videoTrack;
            const container = document.getElementById("myvideo");
            const remotePlayerContainer = document.createElement("div");
            remotePlayerContainer.id = 'dock' + user.uid.toString();
            remotePlayerContainer.className = 'dock' + user.uid.toString();
            remotePlayerContainer.style.width = '100%';
            remotePlayerContainer.style.height = '100%';
            var childrenWithClass = container.getElementsByClassName('dock' + user.uid.toString());
            if (childrenWithClass.length > 0) {
              var div = document.getElementsByClassName(remotePlayerContainer.id);
              remoteVideoTrack.play(remotePlayerContainer);
            } else {
              container.append(remotePlayerContainer);
              var div = document.getElementsByClassName(remotePlayerContainer.id);
              remoteVideoTrack.play(remotePlayerContainer);
            }



          }

          if (mediaType == "audio") {
            const remoteAudioTrack = user.audioTrack;
            remoteAudioTrack.play();
          }
        }
        else if (device == "drone") {
          elementID = "dronePlayer";
          this.droneLiveShow = true;
          this.startdrone = true;
          this.updateloading(loading)
          this.updateshowlive('droneloading')
          this.$message({ message: "开始无人机直播", type: "success" });
          if (mediaType == "video") {
            this.droneshowlive = true;
            const remoteVideoTrack = user.videoTrack;
            const container = document.getElementById("dronevideo");
            const remotePlayerContainer = document.createElement("div");
            remotePlayerContainer.id = 'drone' + user.uid.toString();
            remotePlayerContainer.className = 'drone' + user.uid.toString();
            remotePlayerContainer.style.width = '100%';
            remotePlayerContainer.style.height = '100%';
            var childrenWithClass = container.getElementsByClassName('drone' + user.uid.toString());
            if (childrenWithClass.length > 0) {
              var div = document.getElementsByClassName(remotePlayerContainer.id);
              remoteVideoTrack.play(remotePlayerContainer);
            } else {
              container.append(remotePlayerContainer);
              var div = document.getElementsByClassName(remotePlayerContainer.id);
              remoteVideoTrack.play(remotePlayerContainer);
            }

          }

          if (mediaType == "audio") {
            const remoteAudioTrack = user.audioTrack;
            remoteAudioTrack.play();
          }
        }


      });

      rtc.client.on("user-unpublished", user => {
        if (device == 'dock') {
          sessionStorage.setItem('dockshowlive', false)

          this.showlive = false;
          this.startdock = false;
          this.closeshare(device);
          this.leaveLive(rtc, device);
          this.stopDock(this.dockSN, this.dockvideoid);
          this.updateshowlive(device)
          // var dock = document.getElementById('myvideo');
          // while(dock.childNodes.length > 1) {
          //     dock.removeChild(dock.childNodes[1]);
          // }
          const remotePlayerContainer = document.getElementById('dock' + user.uid.toString());
          remotePlayerContainer.remove();
        } else if (device == 'drone') {
          sessionStorage.setItem('droneshowlive', false)
          this.droneshowlive = false;
          this.startdrone = false;
          this.closeshare(device);
          this.leaveLive(rtc, device);
          this.stopDrone(this.droneSN, this.dronevideoid);
          this.updateshowlive(device)
          // var dock = document.getElementById('dronevideo');
          // while(dock.childNodes.length > 1) {
          //     dock.removeChild(dock.childNodes[1]);
          // }
          const remotePlayerContainer = document.getElementById('drone' + user.uid.toString());
          remotePlayerContainer.remove();
        }

      });
    },
    async leaveLive(rtc, device) { //关闭直播窗口
      var elementID = "";
      if (device == "dock") {
        elementID = "dockPlayer";
        this.dockLiveShow = false;
        rtc.localAudioTrack && rtc.localAudioTrack.close();
        rtc.localVideoTrack && rtc.localVideoTrack.close();
        // Traverse all remote users.
        rtc.client?.remoteUsers.forEach(user => {
          // Destroy the dynamically created DIV containers.
          const playerContainer = document.getElementById('dock' + user.uid);
          playerContainer && playerContainer.remove();
        });

        // Leave the channel.
        await rtc.client?.leave();
      }
      else {
        elementID = "dronePlayer";
        this.droneLiveShow = false;
        rtc.localAudioTrack && rtc.localAudioTrack.close();
        rtc.localVideoTrack && rtc.localVideoTrack.close();
        // Traverse all remote users.
        rtc.client?.remoteUsers.forEach(user => {
          // Destroy the dynamically created DIV containers.
          const playerContainer = document.getElementById('drone' + user.uid);
          playerContainer && playerContainer.remove();
        });

        // Leave the channel.
        await rtc.client?.leave();
      }

    },
    handleClose(done) { //离开页面时关闭直播间
      if (this.droneLiveStatus == 1) {
        // this.stopDroneLive();
        this.leaveLive(this.droneRtc, "drone");
      }
      if (this.dockLiveStatus == 1) {
        // this.stopDockLive();
        this.leaveLive(this.dockRtc, "dock");
      }
      done();
    },

    stopDockLive(devicesn, videoid) { //关闭机场直播
      var devicesn = this.devicesn ? this.devicesn : devicesn;
      var videoid = this.dockvideoid ? this.dockvideoid : videoid;
      requestfly.post("/Live/LiveStopPush?devicesn=" + devicesn+"&devicetype=0").then((res) => {
        var result = res;
        if (result.successful) {
          this.$message({ message: "关闭机场直播成功", type: "success" });
          this.dockLiveStatus = 0;
        } else {
          this.$message.error(result.message);
        }
      }).catch(err => {
        this.$message.error(err.message);
      })
    },
    stopDroneLive(devicesn, videoid) { //关闭无人机直播
      var devicesn = this.dronedevicesn ? this.dronedevicesn : devicesn;
      var videoid = this.dockvideoid ? this.dronevideoid : videoid;
      requestfly.post("/Live/LiveStopPush?devicesn=" + devicesn+"&devicetype=1").then((res) => {
        var result = res;
        if (result.successful) {
          this.$message({ message: "关闭无人机直播成功", type: "success" });
          this.droneLiveStatus = 0;
        } else {
          this.$message.error(result.message);
        }
      }).catch(err => {
        this.$message.error(err.message);
      })
    },
    stopDock(devicesn, videoid) { //关闭机场直播
      var devicesn = this.devicesn ? this.devicesn : devicesn;
      var videoid = this.dockvideoid ? this.dockvideoid : videoid;
      requestfly.post("/Live/LiveStopPush?devicesn=" + devicesn+"&devicetype=0").then((res) => {
        var result = res;
        if (result.successful) {
          this.$alert('机场直播已被中断', {
            confirmButtonText: '确定',

          });
          this.dockLiveStatus = 0;
        } else {
          this.$message.error(getReason(result.message) + ",错误代码:" + result.message);
        }
      }).catch(err => {
        this.$message.error(err.message);
      })
    },
    stopDrone(devicesn, videoid) { //关闭无人机直播
      var devicesn = this.dronedevicesn ? this.dronedevicesn : devicesn;
      var videoid = this.dockvideoid ? this.dronevideoid : videoid;
      requestfly.post("/Live/LiveStopPush?devicesn=" + devicesn +"&devicetype=1").then((res) => {
        var result = res;
        if (result.successful) {
          this.$alert('无人机直播已被中断', {
            confirmButtonText: '确定',

          });
          this.droneLiveStatus = 0;
        } else {
          this.$message.error(result.message);
        }
      }).catch(err => {
        this.$message.error(err.message);
      })
    },
    liveStop(devicesn, videoid) {//关闭直播
      var devicesn = this.devicesn ? this.devicesn : devicesn;
      var videoid = this.videoid ? this.videoid : videoid;
      requestfly.post("/Live/LiveStopPush?devicesn=" + devicesn).then((res) => {
        var result = res;
        if (result.successful) {
          this.$message({ message: "关闭直播成功", type: "success" });
        } else {
          this.$message.error(result.message);
        }
      }).catch(err => {
        this.$message.error(err.message);
      })
    },
    stopLive() {
      var devicesn = this.LiveDevice.SN;
      var videoid = this.LiveDevice.videoID;
      this.dockLiveLoading = true;
      requestfly.post("/Live/LiveStopPush?devicesn=" + devicesn).then((res) => {
        var result = res;
        this.dockLiveLoading = false;
        if (result.successful) {
          // this.$message({message: "关闭直播成功", type: "success"});
          this.mainleaveLive(this.rtc);

          this.dockLiveStatus = 0;
        } else {
          this.$message.error("错误：" + result.message);
        }
      }).catch(err => {
        this.$message.error(err.message);
      })
    },
    closeAll2(event) {
      if (event.name == "ca") {
        this.editableTabs = [];
      }
    },
    newTab(name, url) {
      var menu = null;
      let paramValue = {};
      let queryParams = window.location.search;
      if (queryParams) {
        queryParams?.substring(1).split('&').forEach(pair => {
          const [key, value] = pair.split('=');
          paramValue[key] = decodeURIComponent(value);
        });
      }
      if (!url) {
        this.menus.forEach(item => {
          item.childs.forEach(child => {
            if (child.name == name) {
              menu = child;
              return false;
            }
          });
          if (menu != null)
            return false;
        });
      }
      for (let key in paramValue) {
        if (key == 'projectID' || key == 'userID' || key == 'token') {
          url += url.includes('?') ? '&' + key + '=' + paramValue[key] : '?' + key + '=' + paramValue[key];
        }
      }


      if (menu == null) {
        menu = { name: name, url: url };
      }
      this.addTab(menu);
    },
    addDroneLog(SN, modelName) {
      const userID = JSON.parse(GET_PLAYLOAD()['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/sid'])
      var obj = { userID: userID, modelName: modelName, deviceSN: SN }
      requestfly.post("/OSD/addDroneLog", JSON.stringify(obj));
    },
    isLocalEnvironment() {
      return /^https?:\/\/localhost/.test(window.location.href) ||
        /^file:\//.test(window.location.protocol);
    },
    addTab(menu) {
      // if (window.parent.updatemenu) {
      //   window.parent.updatemenu(menu);
      // }
      let parts = menu.url.split('?');
      let path = parts[0]; // 获取路径部分
      let queryString = parts[1]; // 获取查询字符串部分
      let url = path.replace(/\.\.\//g, '/');
      // 检查是否存在查询字符串
      if (queryString) {
        // 拆分查询参数
        let params = queryString.split('&');
        // 创建一个对象来存储查询参数
        let queryParams = {};
        params.forEach(param => {
          let [key, value] = param.split('=');
          queryParams[key] = value;
        });
        this.$router.push({
          path: url,
          query: queryParams
        });
      } else {
        // 如果没有查询字符串，直接跳转
        this.$router.push(url);
      }
    },

  },
}
</script>
<style lang="scss">
@import '@/styles/video';

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to

/* .fade-leave-active 在 2.1.8+ 中 */
  {
  opacity: 0;
}

.popperoption .el-time-panel {
  left: -22px !important;
}

.custom-icon {
  display: inline-block;
  width: 20px;
  /* 根据你的图标大小调整 */
  height: 20px;
  background-image: url('@/assets/Content/Images/cancel.png');
  background-size: contain;
  background-repeat: no-repeat;
  vertical-align: middle;
  position: absolute;
  right: 27px;
  z-index: 9999;
  cursor: pointer;
  font-size: 16px
}

#dronevideo {
  position: absolute;
  min-width: 320px;
  min-height: 200px;
  max-width: 1280px;
  max-height: 960px;
  width: 100%;
  height: 100%;
  z-index: 3000;
}

.droneshowbottom {
  width: 100%;
  height: 25px;
  position: absolute;
  top: 0px;
  z-index: 3000;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(128, 128, 128, 0.8);
}

#droneclose {
  position: absolute;
  right: 10px;
  top: 5px;
  z-index: 3001;
  cursor: pointer;
  font-size: 16px
}

.dronecustomicon {
  display: inline-block;
  width: 20px;
  /* 根据你的图标大小调整 */
  height: 20px;
  background-image: url('@/assets/Content/Images/cancel.png');
  background-size: contain;
  background-repeat: no-repeat;
  vertical-align: middle;
  position: absolute;
  right: 27px;
  z-index: 3001;
  cursor: pointer;
  font-size: 16px
}

#dronescreen {
  position: absolute;
  right: 30px;
  top: 5px;
  z-index: 3001;
  cursor: pointer;
  font-size: 16px
}

.dockshareshow {
  position: absolute;
  background: #232323;
  width: 300px;
  height: 100%;
  z-index: 3001;
  left: -300px;
}

.dock-header {
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  background: rgb(40, 40, 40);
}

.dock-main {
  width: calc(100% - 40px);
  margin: 20px 20px;
  color: #fff;
  height: 100%;
}

.dock-content {
  color: rgb(134, 134, 134);
  width: 100%;
  height: 70px;
  font-size: 14px;


}

.shareurl {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;

}

.shareurlright {
  width: calc(100% - 90px);
  color: rgb(134, 134, 134);
  font-size: 14px;
  white-space: normal;
  word-wrap: break-word;
  overflow-wrap: break-word;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  /* 限制在3行 */
  overflow: hidden;
  text-overflow: ellipsis;
  /* 显示省略号 */
  cursor: pointer;
}

.qrcodebox {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

#qrcode {
  width: 200px;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
}

.showshare {
  background: #232323;
  height: 400px;
  width: 300px;
  margin: 0 -20px;
}

.showsharebox {
  width: calc(100% - 40px);
  margin: 0 20px;
}

/**/
.droneshareshow {
  position: absolute;
  background: #232323;
  width: 300px;
  height: 100%;
  z-index: 3001;
  left: -300px;
}

.drone-header {
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  background: rgb(40, 40, 40);
}

.drone-main {
  width: calc(100% - 40px);
  margin: 20px 20px;
  color: #fff;
  height: 100%;
}

.drone-content {
  color: rgb(134, 134, 134);
  width: 100%;
  height: 70px;
  font-size: 14px;
}

#droneqrcode {
  width: 200px;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
}

.dockshareshow .el-tooltip__popper.is-dark {
  width: 200px;
}

.droneshareshow .el-tooltip__popper.is-dark {
  width: 200px;
}

.el-message {
  z-index: 3005 !important;
}

.tooltip-width {
  width: 200px !important;
  z-index: 3005 !important;
}
</style>
